import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input, notification, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);

  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    // console.log("finish", values)
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ borderRadius: 15 }}>
        <div style={{ backgroundColor: '#dab81d' }} className="gx-text-center " >
          <Row >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <img src={require("assets/images/login/kpc_login_logo.png")} style={{ marginTop: 20, width: 250 }} />
            </Col>

          </Row>
          <div style={{ backgroundColor: '#ffffff' }} className="gx-app-div-padding">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                initialValue=""
                rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="username">
                <Input placeholder="Username" value="admin" />
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input type="password" placeholder="Password" value="123456" />
              </Form.Item>
              <Form.Item className="gx-text-right">
                <Row>
                  <Col span={24} className="gx-text-right">
                    <Button className="gx-mb-0" htmlType="submit" style={{ background: '#dab81d' }}>
                      {/* <IntlMessages id="app.userAuth.signIn" /> */}
                      SignIn
                    </Button>
                  </Col>
                </Row>

              </Form.Item>
            </Form>
          </div>
        </div>
      </div >
      <InfoView />
      {/* <footer className="gx-text-center gx-mt-5">
        <h6>Powered By:</h6>
        <img alt="example" style={{ height: 25 }} src={require("")} />
      </footer> */}


    </div >
    // <div className="gx-app-login-wrap">
    //   <div className="gx-app-login-container gx-text-center">
    //     <div style={{ backgroundColor: '#dab81d' }} className="gx-app-login-main-content ">
    //       <img src={require("assets/images/login/kpc_login_logo.png")} style={{ marginTop: 20 }} />

    //     </div>
    //     <div className="gx-app-login-main-content">
    //       <div className="gx-app-login-content">
    //         <Form
    //           initialValues={{ remember: true }}
    //           name="basic"
    //           onFinish={onFinish}
    //           onFinishFailed={onFinishFailed}
    //           className="gx-signin-form gx-form-row0">

    //           <Form.Item
    //             initialValue=""
    //             rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="username">
    //             <Input placeholder="Username" value="admin" />
    //           </Form.Item>
    //           <Form.Item
    //             initialValue=""
    //             rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
    //             <Input type="password" placeholder="Password" value="123456" />
    //           </Form.Item>
    //           {/* <Form.Item>
    //             <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
    //             <span className="gx-signup-form-forgot gx-link"><IntlMessages
    //               id="appModule.termAndCondition" /></span>
    //           </Form.Item> */}

    //           <Form.Item className="gx-text-right">
    //             <Row>
    //               <Col span={12} className="gx-text-left">

    //                 <h6>Powered By:</h6>
    //                 <img alt="example" style={{ height: 18 }} src={require("assets/images/login/arclabs_logo.png")} />
    //               </Col>
    //               <Col span={12} className="gx-text-right">
    //                 <Button className="gx-mb-0" htmlType="submit" style={{ background: '#dab81d' }}>
    //                   {/* <IntlMessages id="app.userAuth.signIn" /> */}
    //                   SignIn
    //                 </Button>
    //               </Col>
    //             </Row>

    //           </Form.Item>
    //         </Form>
    //       </div>
    //       <InfoView />
    //     </div>
    //   </div>
    // </div>
  );
};

export default SignIn;
