import axios from 'axios';

export default axios.create({
  baseURL: "https://api.import.kpc-express.com",
  // baseURL: "https://kpc.arclabsdev.com",
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': 'Bearer '+ localStorage.getItem("token")
  }
});

export const apiUrl = "https://api.import.kpc-express.com";
// export const apiUrl = "https://kpc.arclabsdev.com";