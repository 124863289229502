import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
      <div className="gx-main-content-wrapper">
            <Switch>
                  {/* Dashboard */}
                  <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />

                  {/* Shipment management  */}
                  <Route path={`${match.url}shipment-management/shipments`} component={asyncComponent(() => import('./shipmentsManagement/shipment'))} />

                  {/* User management  */}
                  <Route path={`${match.url}user-management/customer`} component={asyncComponent(() => import('./userManagement/customer'))} />
                  <Route path={`${match.url}user-management/employee`} component={asyncComponent(() => import('./userManagement/employee'))} />
                  <Route path={`${match.url}user-management/administrator`} component={asyncComponent(() => import('./userManagement/administrator'))} />

                  
                  {/* My Account  */}
                  <Route path={`${match.url}view-my-account`} component={asyncComponent(() => import('./MyAccount'))} />

                  {/* KPC Wallet  */}
                  <Route path={`${match.url}kpc-wallet/vouchers`} component={asyncComponent(() => import('./KPCWallet/Vouchers'))} />
                  <Route path={`${match.url}kpc-wallet/formula-managements`} component={asyncComponent(() => import('./KPCWallet/formulaManagement'))} />

                  {/* General Settings  */}
                  <Route path={`${match.url}email-blast`} component={asyncComponent(() => import('./EmailBlast'))} />

                  {/* Audit Trail  */}
                  <Route path={`${match.url}audit-trail`} component={asyncComponent(() => import('./AuditTrail'))} />

                  {/* General Settings  */}
                  <Route path={`${match.url}general-settings/payment-status`} component={asyncComponent(() => import('./GeneralSettings/PaymentStatus'))} />
                  <Route path={`${match.url}general-settings/delivery-status`} component={asyncComponent(() => import('./GeneralSettings/DeliveryStatus'))} />
                  <Route path={`${match.url}general-settings/warehouse-country`} component={asyncComponent(() => import('./GeneralSettings/Warehouse'))} />
                  <Route path={`${match.url}general-settings/membership-type`} component={asyncComponent(() => import('./GeneralSettings/MembershipType'))} />
                  <Route path={`${match.url}general-settings/payment-options`} component={asyncComponent(() => import('./GeneralSettings/PaymentMethods'))} />
                  <Route path={`${match.url}general-settings/pickup-warehouse`} component={asyncComponent(() => import('./GeneralSettings/pickupWarehouse'))} />
                  <Route path={`${match.url}general-settings/address-list`} component={asyncComponent(() => import('./GeneralSettings/addressList'))} />
                  <Route path={`${match.url}general-settings/cbm-formulas`} component={asyncComponent(() => import('./GeneralSettings/cbmFormulas'))} />
                  <Route path={`${match.url}general-settings/other-formulas`} component={asyncComponent(() => import('./GeneralSettings/otherFormulas'))} />

                  <Route path={`${match.url}system-management/payment-gateway`} component={asyncComponent(() => import('./systemManagement/paymentGateway'))} />
                  {/*
      General Settings
      <Route path={`${match.url}general-settings/payment-status`} component={asyncComponent(() => import('./generalSettings/paymentStatus'))}/>
      <Route path={`${match.url}general-settings/delivery-status`} component={asyncComponent(() => import('./generalSettings/deliveryStatus'))}/>
      <Route path={`${match.url}general-settings/warehouse-country`} component={asyncComponent(() => import('./generalSettings/warehouseCountry'))}/>
      <Route path={`${match.url}general-settings/pickup-warehouse`} component={asyncComponent(() => import('./generalSettings/pickupWarehouse'))}/>
      <Route path={`${match.url}general-settings/movement-fee`} component={asyncComponent(() => import('./generalSettings/movementFee'))}/>
      <Route path={`${match.url}general-settings/address-list`} component={asyncComponent(() => import('./generalSettings/addressList'))}/>
      <Route path={`${match.url}general-settings/membership-type`} component={asyncComponent(() => import('./generalSettings/membershipType'))}/>
      <Route path={`${match.url}general-settings/pg-credentials`} component={asyncComponent(() => import('./generalSettings/pgCredentials'))}/>
      <Route path={`${match.url}general-settings/payment-options`} component={asyncComponent(() => import('./generalSettings/paymentOptions'))}/>
      <Route path={`${match.url}general-settings/cbm-formulas`} component={asyncComponent(() => import('./generalSettings/cmbFormulas'))}/>
      <Route path={`${match.url}general-settings/other-formulas`} component={asyncComponent(() => import('./generalSettings/otherFormulas'))}/>
     */}
            </Switch>
      </div>
);

export default App;
