import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const myAccountPage = () =>{
    history.push('/view-my-account')
  }
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => myAccountPage()}>My  Account</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (

    // <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
    <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src='https://via.placeholder.com/150x150'
        className="gx-size-35 gx-pointer gx-mr-3 gx-mb-1" alt="" />
      <span className="gx-avatar-name">
        Welcome, {localStorage.getItem("userName")}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
      </span>
    </Popover>
    // </div>

  )
};

export default UserProfile;
