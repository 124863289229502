import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  DashboardOutlined, SettingOutlined, AppstoreOutlined
} from '@ant-design/icons';

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const { SubMenu } = Menu;
const SidebarContent = () => {

  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let { pathname } = useSelector(({ common }) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const MenuItemGroup = Menu.ItemGroup;

  return (<>

    <SidebarLogo />
    <div className="gx-sidebar-content">

      <CustomScrollbars className="gx-layout-sider-scrollbar">
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          // theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode="inline">

          <Menu.Item key="dashboard">
            <Link to="/dashboard">
              <i className="icon icon-dasbhoard" />
              <span><IntlMessages id="sidebar.dashboard" /></span>
            </Link>
          </Menu.Item>

          <MenuItemGroup key="main-shipment-management" className="gx-menu-group" title="Shipment Management">
            <Menu.Item key="shipment-management/shipments">
              <Link to="/shipment-management/shipments">
                <i className="icon icon-shopping-cart" />
                <span><IntlMessages id="sidebar.shipment.management.shipments" /></span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>




          <MenuItemGroup key="main-user-management" className="gx-menu-group" title="User Management">
            <Menu.Item key="user-management/administrator">
              <Link to="/user-management/administrator">
                <i className="icon icon-profile" />
                <span><IntlMessages id="sidebar.user.management.administrator" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="user-management/employee">
              <Link to="/user-management/employee">
                <i className="icon icon-profile" />
                <span><IntlMessages id="sidebar.user.management.employee" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="user-management/customer">
              <Link to="/user-management/customer">
                <i className="icon icon-profile" />
                <span><IntlMessages id="sidebar.user.management.customer" /></span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>

          <MenuItemGroup key="main-kpc-wallet" className="gx-menu-group" title="Kpc Wallet">
            <Menu.Item key="kpc-wallet/vouchers">
              <Link to="/kpc-wallet/vouchers">
                <i className="icon icon-star-o" />
                <span><IntlMessages id="sidebar.kpc-wallet.vouchers" /></span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="kpc-wallet/formula-managements">
              <Link to="/kpc-wallet/formula-managements">
                <i className="icon icon-tag-o" />
                <span><IntlMessages id="sidebar.kpc.wallet.formula.managements" /></span>
              </Link>
            </Menu.Item> */}
          </MenuItemGroup>

          <MenuItemGroup key="main-system-management" className="gx-menu-group" title="System Management">
            <Menu.Item key="system-management/payment-gateway">
              <Link to="/system-management/payment-gateway">
                <i className="icon icon-transfer" />
                <span><IntlMessages id="sidebar.system.management.payment.gateway" /></span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>

          <MenuItemGroup key="main-email-blast" className="gx-menu-group" title="Email Notification">
            <Menu.Item key="email-blast">
              <Link to="/email-blast">
                <i className="icon icon-email" />
                <span><IntlMessages id="sidebar.email.blast" /></span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>



          {/* <MenuItemGroup key="main-audit-trail" className="gx-menu-group" title="Audit Trail">
            <Menu.Item key="audit-trail">
              <Link to="/audit-trail">
                <i className="icon icon-table-data" />
                <span><IntlMessages id="sidebar.audit.trail" /></span>
              </Link>
            </Menu.Item>
          </MenuItemGroup> */}

          <MenuItemGroup key="main-general-settings" className="gx-menu-group" title="General Settings">
            <Menu.Item key="general-settings/warehouse-country">
              <Link to="/general-settings/warehouse-country">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.warehouse.country" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="general-settings/pickup-warehouse">
              <Link to="/general-settings/pickup-warehouse">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.pickup.warehouse" /></span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="general-settings/movement-fee">
              <Link to="/general-settings/movement-fee">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.movement.fee" /></span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="general-settings/address-list">
              <Link to="/general-settings/address-list">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.address.list" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="general-settings/membership-type">
              <Link to="/general-settings/membership-type">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.membership.type" /></span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="general-settings/pg-credentials">
              <Link to="/general-settings/pg-credentials">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.pg.credentials" /></span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="general-settings/payment-options">
              <Link to="/general-settings/payment-options">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.payment.methods" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="general-settings/cbm-formulas">
              <Link to="/general-settings/cbm-formulas">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.cbm.formulas" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="general-settings/other-formulas">
              <Link to="/general-settings/other-formulas">
                <i className="icon icon-setting" />
                <span><IntlMessages id="sidebar.general.settings.other.formulas" /></span>
              </Link>
            </Menu.Item>
            <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Status">
              <Menu.Item key="general-settings/payment-status">
                <Link to="/general-settings/payment-status">
                  <i className="icon icon-setting" />
                  <span><IntlMessages id="sidebar.general.settings.payment.status" /></span>
                </Link>
              </Menu.Item>
              <Menu.Item key="general-settings/delivery-status">
                <Link to="/general-settings/delivery-status">
                  <i className="icon icon-setting" />
                  <span><IntlMessages id="sidebar.general.settings.delivery.status" /></span>
                </Link>
              </Menu.Item>
            </SubMenu>
          </MenuItemGroup>

        </Menu>
      </CustomScrollbars>
    </div>
  </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

