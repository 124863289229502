import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_ROLE_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET, USER_ROLE_SET,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import axios from 'util/Api';
import { post, get } from "axios";
import { apiUrl } from 'util/Api';
import { notification } from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  // console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/register', {
      email: email,
      password: password,
      name: name
    }
    ).then(({ data }) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        // console.log("payload: data.error", data.error);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      // console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({ username, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    post(`${apiUrl}/api/login/`, {
      username: username,
      password: password,
    }
    ).then(({ data }) => {
      // console.log("userSignIn: ", data.data);
      if (data.data) {
        // console.log(typeof( data.data.access_token))
        // console.log(data.data)
        localStorage.setItem("token", data.data.access_token);
        localStorage.setItem("userId", JSON.stringify(data.data.id));
        localStorage.setItem("userRole", JSON.stringify(data.data.role));
        localStorage.setItem("userName", data.data.firstName);
        localStorage.setItem("accountId", data.data.user_id);
        localStorage.setItem("username", data.data.username);
        if(data.data.role==1){
          localStorage.setItem("isCanUpload", JSON.stringify(data.data.is_can_updload));
        }
        if(data.data.role==2){
          localStorage.setItem("readPermission", JSON.stringify(data.data.permission.read));
          localStorage.setItem("createPermission", JSON.stringify(data.data.permission.create));
          localStorage.setItem("updatePermission", JSON.stringify(data.data.permission.update));
          localStorage.setItem("archivePermission", JSON.stringify(data.data.permission.archive));
          // axios.defaults.headers.common['Authorization'] = "Bearer " + data.data.access_token;

        }
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_ROLE_SET, payload: data.data.role });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.access_token });


      } else {
        // console.log(data)
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      // console.log(error.message)
      dispatch({
        type: SHOW_MESSAGE,
        payload: error.detail,
      });
      notification.open({
        message: 'Alert!',
        description: "Unable to access using the provided credentials",
      });
    });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('auth/me',
    ).then(({ data }) => {
      // console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      // console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    // console.log(localStorage.getItem('token'))
    post(`${apiUrl}/api/logout/`, {
      token: localStorage.getItem('token'),
    }).then(({ data }) => {
      // console.log("log out", data)
      if (data.data) {
        localStorage.clear();
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      // console.log("Error****:", error.message);
    });
  }
};
